
body {
    background: #282C34;
    font-family: 'Varela', sans-serif;
  }
  
  .glitch {
    color: white;
    font-size: 100px;
    position: relative;
    width: 200px;
    margin: auto;
    margin-top: 250px;
  }
  .glitch2 {
    color: white;
    font-size: 100px;
    font-family: 'Quicksand';
    position: relative;
    width: 375px;
    margin: auto;
    /* margin-top: 250px; */
  }
  
  @keyframes noise-anim {
    0% {
      clip: rect(27px, 9999px, 63px, 0);
    }
    5% {
      clip: rect(46px, 9999px, 78px, 0);
    }
    10% {
      clip: rect(55px, 9999px, 21px, 0);
    }
    15% {
      clip: rect(2px, 9999px, 14px, 0);
    }
    20% {
      clip: rect(9px, 9999px, 40px, 0);
    }
    25% {
      clip: rect(62px, 9999px, 75px, 0);
    }
    30% {
      clip: rect(50px, 9999px, 64px, 0);
    }
    35% {
      clip: rect(12px, 9999px, 61px, 0);
    }
    40% {
      clip: rect(70px, 9999px, 91px, 0);
    }
    45% {
      clip: rect(90px, 9999px, 40px, 0);
    }
    50% {
      clip: rect(30px, 9999px, 2px, 0);
    }
    55% {
      clip: rect(21px, 9999px, 78px, 0);
    }
    60% {
      clip: rect(100px, 9999px, 66px, 0);
    }
    65% {
      clip: rect(99px, 9999px, 67px, 0);
    }
    70% {
      clip: rect(28px, 9999px, 27px, 0);
    }
    75% {
      clip: rect(28px, 9999px, 78px, 0);
    }
    80% {
      clip: rect(60px, 9999px, 21px, 0);
    }
    85% {
      clip: rect(52px, 9999px, 56px, 0);
    }
    90% {
      clip: rect(94px, 9999px, 41px, 0);
    }
    95% {
      clip: rect(38px, 9999px, 2px, 0);
    }
    100% {
      clip: rect(4px, 9999px, 14px, 0);
    }
  }
  .glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 500;
    color: white;
    background: #282C34;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
  }
  .glitch2:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 500;
    color: white;
    background: #282C34;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
  }
  
  @keyframes noise-anim-2 {
    0% {
      clip: rect(49px, 9999px, 10px, 0);
    }
    5% {
      clip: rect(36px, 9999px, 60px, 0);
    }
    10% {
      clip: rect(48px, 9999px, 41px, 0);
    }
    15% {
      clip: rect(25px, 9999px, 65px, 0);
    }
    20% {
      clip: rect(90px, 9999px, 42px, 0);
    }
    25% {
      clip: rect(18px, 9999px, 29px, 0);
    }
    30% {
      clip: rect(24px, 9999px, 92px, 0);
    }
    35% {
      clip: rect(41px, 9999px, 41px, 0);
    }
    40% {
      clip: rect(60px, 9999px, 72px, 0);
    }
    45% {
      clip: rect(90px, 9999px, 50px, 0);
    }
    50% {
      clip: rect(85px, 9999px, 46px, 0);
    }
    55% {
      clip: rect(72px, 9999px, 8px, 0);
    }
    60% {
      clip: rect(43px, 9999px, 48px, 0);
    }
    65% {
      clip: rect(83px, 9999px, 62px, 0);
    }
    70% {
      clip: rect(85px, 9999px, 51px, 0);
    }
    75% {
      clip: rect(81px, 9999px, 76px, 0);
    }
    80% {
      clip: rect(63px, 9999px, 100px, 0);
    }
    85% {
      clip: rect(35px, 9999px, 18px, 0);
    }
    90% {
      clip: rect(49px, 9999px, 99px, 0);
    }
    95% {
      clip: rect(4px, 9999px, 65px, 0);
    }
    100% {
      clip: rect(51px, 9999px, 15px, 0);
    }
  }
  .glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 500;
    color: white;
    background: #282C34;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
  }
  .glitch2:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 500;
    color: white;
    background: #282C34;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
  }