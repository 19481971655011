
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Coiny&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');

.App {
  text-align: center;
}

.sayfaIciText {
  font-family: 'Poppins', sans-serif;
}
.logoOnYazi {
  font-family: "Tangerine", cursive;
}

.yatayDikeyOrtala{
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  /* overflow: scroll; */
  overflow-x: hidden;
  /* overflow-y: hidden; */
  /* background-color: rgb(250, 235, 215); */
  background-color: black;
  
}

::-webkit-scrollbar {
  width: 0;  
  background: transparent;  
  /* display: none; */
}
::-webkit-scrollbar-thumb {
  background: #585858;
}